<template>
  <v-data-table
    :headers="headers"
    :items="purchaseOtherPayments"
    dense
    @click:row="onClickRow"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-col cols="12" sm="12" md="9" lg="9">
            <v-text-field
              flat
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3" class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="success"
                  v-on="on"
                  dark
                  rounded
                  :to="{ name: 'Create Forwarder Payment' }"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Create</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ purchaseOtherPayments.map(x => x).indexOf(item) + 1 }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      // {
      //   id: 2,
      //   text: "Form Number",
      //   value: "formNumber",
      //   sortable: false,
      //   align: "center",
      //   divider: true,
      //   class: "light-blue lighten-2 font-weight-black",
      // },
      {
        id: 3,
        text: "Forwarder",
        value: "vendorName",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Pay Date",
        value: "paymentDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Method",
        value: "methodPayment",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Status",
        value: "status",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    search: "",
  }),

  computed: {
    ...mapState("purchaseOtherPayment", ["purchaseOtherPayments"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch("purchaseOtherPayment/getAll");
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Forwarder Payment", params: { id: item.id } });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
